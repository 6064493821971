import Vue from "vue"
import { required, email, max, min, min_value, max_value, confirmed } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"

extend("required", {
  ...required,
  message: "This field is required and cannot be empty"
});

extend("max", {
  ...max,
  message: "Kolom maximum {length} karakter"
});

extend("min", {
  ...min,
  message: "Kolom minimum {length} karakter"
});

extend("min_value", {
  ...min_value,
  message: "Input field minimum {min}"
});

extend("max_value", {
  ...max_value,
  message: "Input field maximum {max}"
});

extend("min_10000", {
  ...min_value,
  message: "Kolom minimum 10000"
});

extend("min_0", {
  ...min_value,
  message: "Kolom minimum 0"
});

extend("min_1", {
  ...min_value,
  message: "Kolom minimum 1"
});

extend("max_100", {
  ...max_value,
  message: "Kolom maximum 100"
});

extend("email", {
  ...email,
  message: "Kolom email belum benar"
})

extend("confirmed", {
  ...confirmed,
  message: "Kolom konfirmasi password belum benar"
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  }
}